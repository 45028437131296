import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    // eslint-disable-next-line
    async getSubscriptionsBySite({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/subscriptions/site/' + uuid).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getRecurringServices() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/recurringServices').then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateSubscription(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/subscriptions/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createSubscription(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/subscriptions', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
