export const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
}

export const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}
const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
const frenchDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']

export const syncfusionDateToJsDate = (date) => {
    const year = date.substring(0, 4)
    const month = parseInt(date.substring(4, 6)) - 1
    const day = date.substring(6)
    return new Date(year, month, day)
}

export const isDateBeforeAnother = (date1, date2) => {
    date1 = new Date(date1)
    date2 = new Date(date2)
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    return date1 < date2
}

export const recurrenceToString = (recurrence) => {
    if (!recurrence) return 'Une seule fois'
    let suffix = ''
    if (recurrence.includes('UNTIL')) {
        const textUntil = recurrence.split('UNTIL=')[1].split(`T${23 - (Math.abs(new Date().getTimezoneOffset()) / 60)}5959Z`)[0]
        suffix = ' jusqu\'au ' + (syncfusionDateToJsDate(textUntil)).toLocaleDateString() + ' inclus'
    }
    if (recurrence.includes('MONTHLY')) return 'Tous les mois' + suffix
    return 'Tous les ' + recurrence.split('BYDAY=')[1].split(';')[0].split(',').map(d => frenchDays[days.indexOf(d)]).join(', ') + suffix
}

export const cyclicWritingToString = (interval, max = null) => {
    return `Toutes les ${interval} minute${interval > 1 ? 's' : ''}${max ? ` (${max} fois)` : ''}`
}

export const getRecurrenceFormat = (selectedDays, monthly, date, until) => {
    const dateUntil = until ? new Date(until) : ''
    let textUntil
    if (dateUntil) {
        textUntil = dateUntil.getFullYear() + '' + (dateUntil.getMonth() + 1).toString().padStart(2, '0') + '' + dateUntil.getDate().toString().padStart(2, '0')
    }
    if (monthly) return 'FREQ=MONTHLY;BYMONTHDAY=' + (new Date(date)).getDate() + (until ? ';UNTIL=' + textUntil : '')
    if (selectedDays && selectedDays.length)
        return 'FREQ=WEEKLY;BYDAY=' +
            days.filter((day, index) => selectedDays.map(sd => sd.toLowerCase()).includes(frenchDays[index])).join(",") + (until ? ';UNTIL=' + textUntil : '');
    return '';
}

export const getDatapointName = (datapoint) => {
    const displayNameTypeDatapoint = datapoint['type_datapoint'] && datapoint['type_datapoint']['display_name'] !== '' ? datapoint['type_datapoint']['display_name'] : datapoint['description']
    const displayNameDatapoint = datapoint['display_name'] !== '' ? datapoint['display_name'] : ''
    return displayNameTypeDatapoint + (displayNameDatapoint ? ' (' + displayNameDatapoint + ')' : '')
}

export const getSankeyEquipmentLabel = (equipment) => {
    if (equipment.display_name) return equipment.display_name
    const tags_name = equipment.equipment_tags.map(t => t.tags_id.label).join(' / ')
    if (tags_name !== '') return tags_name
    return equipment.name
}

export const getOrderedSankeyLinks = (links, parent, array = []) => {
    const children = links.filter(n => n.from === parent).sort((a, b) => a.to.localeCompare(b.to))
    for (const child of children) {
        array.push(child)
        getOrderedSankeyLinks(links, child.to, array)
    }
    return array
}
export const getAlertsBySelectedSitesAndStatus = (sites, status) => {
    let alerts = 0
    sites.forEach(site => {
        if (status === "unacknowledged") alerts += site.all_alarms_count
        else if (status === "acknowledged") alerts += site.ack_alarms_count
    })
    return alerts
}

export const formatValueToString = (value, type, facets) => {
    try {
        let val;
        let attribute = facets.read ? 'read' : 'write';
        let boolValue;
        switch (type) {
            case 'numeric':
                val = parseFloat(value)
                if (!isNaN(facets.factor)) val *= facets.factor
                if (!isNaN(facets.precision)) val = val.toFixed(facets.precision)
                if (facets.units) val += ` ${facets.units}`
                return val
            case 'string':
                return value
            case 'boolean':
                boolValue = value === true || value === 'true'
                if (value === 'both') boolValue = 'both'
                return facets[attribute][boolValue + 'Text']?? boolValue
            case 'enum':
                return facets[attribute].options?.find(option => option['value'] == value)?.text
        }
    } catch (e) {
        console.log(e)
        return ''
    }

}

export const getBase64FromDownloadedFile = (downloadedFile, callback) => {
    const file = new Blob([new Uint8Array(downloadedFile.data.data)], {
        type: 'application/pdf'
    })
    getBase64(file, (result) => {
        callback(result)
    })
}

export const getBase64FromUploadedFile = (uploadedFile, callback) => {
    const form = new FormData()
    form.append('filename', uploadedFile.files[0].name)
    form.append('file', uploadedFile.files[0])
    getBase64(uploadedFile.files[0], (result) => {
        callback(result, form)
    });
}

export const formatQueryArray = (arrayName, values) => {
    if (values.length === 0) { return '' }
    let formattedArray = ''
    values.forEach((value, index) => {
        formattedArray += `${arrayName}[]=${value + ((values.length - 1 === index) ? '' : '&')}`
    })
    return formattedArray
}

export const generateId = () => {
    return Date.now().toString(36) + Math.random().toString(36)
}

const getWriteDatapointForReadDatapoint = (datapoint, datapoints, values = null) => {
    try {
        const description = datapoint.description
        const split_description = description.split(' ')
        if (split_description.length > 1) {
            if (split_description[split_description.length - 1] === 'W' && datapoint.facets.writable === true) return datapoint
        }
        const description_to_find = description + ' W'
        const equipment_id_to_find = datapoint.equipment_id?.equipment_id || datapoint.equipment_id
        const datapoints_of_same_equipment = datapoints.filter(d => d.equipment_id.equipment_id != undefined ? d.equipment_id.equipment_id === equipment_id_to_find : d.equipment_id === equipment_id_to_find)
        const found_datapoint = datapoints_of_same_equipment.find(d => d.description === description_to_find && d.facets.writable === true)
        if (found_datapoint) {
            if (found_datapoint.facets?.write?.max_heat_setpoint != undefined && values) {
                let cool_mode = null
                let heat_mode = null
                const hvac_control_mode = datapoints_of_same_equipment.find(d => d.description === 'HVAC_Control_Mode')
                const heat_cool_mode = datapoints_of_same_equipment.find(d => d.description === 'Heat_Cool_Mode')
                if (hvac_control_mode) {
                    const hvac_control_mode_value = values[hvac_control_mode.data?.unique_data_id]?.value
                    if (hvac_control_mode_value != undefined) {
                        hvac_control_mode.facets?.read?.options.forEach(option => {
                            if (hvac_control_mode_value.toString() === option.value?.toString()) {
                                if (option['hvac_mode'] == 'cool') {
                                    cool_mode = true
                                    heat_mode = false
                                } else if (option['hvac_mode'] == 'false') {
                                    cool_mode = false
                                    heat_mode = true
                                }
                            }
                        })
                    }
                } else if (heat_cool_mode) {
                    const heat_cool_mode_value = values[heat_cool_mode.data?.unique_data_id]?.value
                    if (heat_cool_mode_value != undefined) {
                        if (heat_cool_mode_value === true || heat_cool_mode_value === 'true') {
                            cool_mode = false
                            heat_mode = true
                        } else if (heat_cool_mode_value === false || heat_cool_mode_value === 'false') {
                            cool_mode = true
                            heat_mode = false
                        }
                    }
                }
                if (heat_mode === true) {
                    found_datapoint.facets.write.max = found_datapoint.facets?.write?.max_heat_setpoint
                    found_datapoint.facets.write.min = found_datapoint.facets?.write?.min_heat_setpoint
                } else if (cool_mode === true) {
                    found_datapoint.facets.write.max = found_datapoint.facets?.write?.max_cool_setpoint
                    found_datapoint.facets.write.min = found_datapoint.facets?.write?.min_cool_setpoint
                }
            }
        }
        return found_datapoint
    } catch (e) {
        console.log(e)
    }
}

/**
 * Use this function only if you sure that this will go in HtmlToPdfMake in back
 * Use to prevent getting some weird (example)->  style=\"color: rgb(0, 102, 204);\">
 * (WARNING) This function also assume that htmlTemplate string to replace will not be used as proper usable text
 * @param htmlTemplate
 */
export const formatHtmlToPdf = (htmlTemplate) => {
    const correctedHtmlTemplate = htmlTemplate
    correctedHtmlTemplate.replaceAll("style=\"", 'style="')
    correctedHtmlTemplate.replaceAll(";\">", ';">')
    return correctedHtmlTemplate
}

export const REGEX_VALIDATORS = {
    EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
}

export { getWriteDatapointForReadDatapoint }