import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    zones: [],
  },
  mutations: {
    SET_ZONES(state, value) {
      state.zones = value
    },
  },
  getters: {
    getZones(state) {
      return state.zones
    },
  },
  actions: {
    async getZonesBySite({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/zones/site/' + data).then(res => {
          let zones = res.data.data
          zones.forEach(zone => {
            let equipments_count = zone.equipments_id.length
            let datapoints_count = 0
            zone.equipments_id.forEach(equipment => {
              datapoints_count += equipment.datapoints_id.length
            })
            zone.equipments_count = equipments_count
            zone.datapoints_count = datapoints_count
          })
          commit('SET_ZONES', zones)
          resolve(zones)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getZonesBySiteSmplrspace({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/zones/site/' + data + '/smplrspace').then(res => {
          let zones = res.data.data
          zones.forEach(zone => {
            let equipments_count = zone.equipments_id.length
            let datapoints_count = 0
            zone.equipments_id.forEach(equipment => {
              datapoints_count += equipment.datapoints_id.length
            })
            zone.equipments_count = equipments_count
            zone.datapoints_count = datapoints_count
          })
          commit('SET_ZONES', zones)
          resolve(zones)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getHvacDataByZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`zones/${data}/hvac`).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getLightingDataByZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`zones/${data}/lighting`).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getIaqDataByZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`zones/${data}/iaq`).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateZone/' + data.zone_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createZone', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteZone(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteZone/' + data.zone_id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async regeneratePwds(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/zones/regeneratePwds/' + data.site_uuid).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getPdfPwds(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/zones/getPdfPwds/' + data.site_uuid).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getZoneNatures() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/zones/natures').then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
