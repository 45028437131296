<template>
	<div class="layout-topbar">
		<div class="layout-topbar-left">
      <Button @click="$emit('toggleMenu')" class="justify-content-center" style="background: none; border: none; width: 50px; height: 50px; border-radius: 30px; margin-left: 20px;">
        <i class="pi pi-align-justify" style="font-size: 2rem;"></i>
      </Button>
			<router-link to="/" class="app-name mr-4">
        <div style="width: 18rem" class="text-center">
          <img style="width: 175px" alt="Logo" :src="logo" />
        </div>
			</router-link>
		</div>

		<div class="layout-topbar-right align-items-center flex" style="height: 48px">
      <iframe
          ref="refInstatus"
          src="https://statut.buildy.fr/embed-status/1fe83dab/light-md"
          width="370"
          height="48"
          frameBorder="0"
          scrolling="no"
          style="border: none;"
      >
      </iframe>
      <Button @click="zoom" icon="fa-solid fa-magnifying-glass-minus" class="ml-4 mr-2"></Button>
      <Button @click="zoom" icon="fa-solid fa-magnifying-glass-plus" class="mr-4"></Button>
<!--      <Button @click="zoom" icon="fa-solid fa-arrows-rotate" class="mr-4"></Button>-->

<!--      <i :key="keyBell" class="pi pi-bell mr-6 ml-4 cursor-pointer" @click="goToAlerts" style="font-size: 2rem; color: white" v-badge.danger="unacknowledgedAlerts"></i>-->
<!--      <ul class="layout-topbar-actions">-->
<!--				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">-->
<!--					<div class="profile-container" @click="onTopbarItemClick($event, 'profile')">-->
<!--            <a href="#">{{ me?.first_name.charAt(0) + me?.last_name.charAt(0) }}</a>-->
<!--            <div class="name-container">-->
<!--              <span class="name">{{ me?.first_name + ' ' + me?.last_name }}</span>-->
<!--              <span class="job">{{ me?.role.name }}</span>-->
<!--            </div>-->
<!--						<i class="pi pi-angle-down"></i>-->
<!--					</div>-->

<!--					<ul class="profile-menu fadeInDown">-->
<!--						<li>-->
<!--							<a href="" @click="logout()">-->
<!--								<span>Déconnexion</span>-->
<!--							</a>-->
<!--						</li>-->
<!--					</ul>-->
<!--				</li>-->
<!--			</ul>-->
		</div>
	</div>
  <Dialog v-model:visible="globalLoading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner />
  </Dialog>
  <ConfirmDialog group="zoom" :pt="{rejectButton: {root: {style: {display: 'none'}}}}">
    <template #message="slotProps">
      <span class="p-confirm-dialog-message" data-pc-section="message" v-html="slotProps.message.message"></span>
    </template>
  </ConfirmDialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
export default {
  emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click', 'toggleMenu', 'zoom', 'unzoom', 'reset'],
  props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
      keyBell: 0,
      finishedSetup: false,
      selectedSites: [],
      selectedCustomers: [],
      customers: null,
      logo: require('@/assets/logo-buildy-mono-clair.svg'),
			menu: [
				{
					label: 'Menu',
					items: [
						{
							label: 'UI Kit', icon: 'pi pi-align-left',
							items: [
								{label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout'},
								{label: 'Input', icon: 'pi pi-check-square', to: '/input'},
							]
						},
						{
							label: 'Hierarchy', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.2', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.1', icon: 'pi pi-align-left'},
									]
								},
							]
						}
					]
				}
			]
		}
	},
  computed: {
    ...mapGetters({
      alerts: 'alerts/getAlerts',
      sites: 'sites/getSites'
    }),
    ...mapState({
      me: state => state.auth.me,
      globalLoading: state => state.misc.globalLoading
    }),
    unacknowledgedAlerts () {
      if (this.sites && this.sites.length) {
        let alerts = 0
        this.sites.forEach(site => {
          alerts += site.all_alarms_count
        })
        return alerts.toString()
      }
      return '0'
    }
  },
  async created () {
    this.$store.state.misc.globalLoading = true
    this.customers = []
    await this.$store.restored
    await this.$store.dispatch('sites/getAllSites')
    this.customers = await this.$store.dispatch('customers/getCustomers')
    this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name)).filter(c => c.status === 'published')
    this.$store.commit('sites/SET_CUSTOMERS', this.customers)
    this.$store.state.misc.globalLoading = false
  },
  mounted() {
    setInterval(() => {
      const iframe = this.$refs.refInstatus
      // eslint-disable-next-line
      if (iframe) iframe.src = iframe.src
      // iframe.contentWindow.location.reload()
    }, 1000 * 60)
  },
  methods: {
    zoom () {
      // this.$emit('zoom')
      this.$confirm.require({
        group: "zoom",
        header: 'ZOOM + / -',
        message: 'Si vous souhaitez augmenter ou diminuer la taille des éléments de l\'application, il vous suffit d\'utiliser les fonctions de "Zoom +" et "Zoom -" intégrées à votre navigateur.<br/><br/>Vous pouvez également zoomer à l\'aide de votre clavier des combinaisons de touches <span style="font-weight: bold">CTRL et +</span> ou <span style="font-weight: bold">CTRL et -</span> sur Windows ou <span style="font-weight: bold">CMD et +</span> ou <span style="font-weight: bold">CMD et -</span> sur Mac',
        accept: () => {
        },
        reject: () => {
        },
        acceptLabel: 'OK'
      });
    },
    unzoom () {
      this.$emit('unzoom')
    },
    reset () {
      this.$emit('reset')
    },
    goToAlerts () {
      this.$router.push({ name: 'alerts_view' })
    },
    async logout () {
      this.$store.state.misc.globalLoading = true
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login'})
      this.$store.state.misc.globalLoading = false
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarItemClick(event, item) {
      if(item === 'search' && this.$refs.input1) {
        setTimeout(() => {
          this.$refs.input1.$el.focus();
          this.$refs.input2.$el.focus();
        }, 400);
      }

      this.$emit('topbar-item-click', {originalEvent: event, item: item});
      event.preventDefault();
    },
	}
}
</script>


<style lang="scss" scoped>
::v-deep(.p-dropdown-label) {
  color: #1B2842
}
</style>