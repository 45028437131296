import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async getGraphSingleData2(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/graph/single/[${data.listDpIds}]/${data.interval}`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getSumData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/graph/sum/[${data.listDpIds}]`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getGraphMultiData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/graph/multi/[${data.listDpIds}]/${data.interval}`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
