import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async getElectricityDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/energy/electricity`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getThermalDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/energy/thermal`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getGasDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/energy/gas`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getWaterDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/energy/water`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getCVCDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/hvac`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getCVCDashboardDataLive(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/hvac/live`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getLightingDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/lighting`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getLightingDashboardDataLive(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/lighting/live`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getQAIDashboardData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/iaq`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getQAIDashboardDataLive(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/dashboard/iaq/live`, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
