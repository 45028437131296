import axios from "@/plugins/axios";

export default {
  namespaced: true,

  actions: {
    async getUserProfiles() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/userProfiles').then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
