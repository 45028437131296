import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    alerts: []
  },
  mutations: {
    SET_ALERTS(state, value) {
      state.alerts = value
    }
  },
  getters: {
    getAlerts(state) {
      return state.alerts
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllAlerts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/alert/all/bms').then(res => {
          commit('SET_ALERTS', res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAlertsDashboard({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/alert/dashboard', data).then(res => {
          commit('SET_ALERTS', res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllAlertsBySite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get(`/alert/all/${data.disappeared?? false}/${data.site}`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllAlertsForSites(_, sites) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/alert/sites`, {sites: sites}).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createAlert(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/alert`, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateAlert(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put(`/alert`, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createNote(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/alert/note`, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async acknowledgeAlert(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post(`/alert/acknowledge/true/${data.alert_id}`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async disappearAlert(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete(`/alert/delete/${data.alert_id}`).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
