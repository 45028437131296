import axios from "@/plugins/axios";
import {getDatapointName} from "@/utils/Misc";

export default {
  namespaced: true,

  state: {
    datapoints: [],
  },
  mutations: {
    SET_DATAPOINTS(state, value) {
      state.datapoints = value
    },
  },
  getters: {
    getDatapoints(state) {
      return state.datapoints.sort((a, b) => {
        if (a.equipment_id?.zone_id?.name !== b.equipment_id?.zone_id?.name) {
          return a.equipment_id?.zone_id?.name?.localeCompare(b.equipment_id?.zone_id?.name)
        }
        if (a.equipment_id?.name !== b.equipment_id?.name) {
          return a.equipment_id?.name?.localeCompare(b.equipment_id?.name)
        }
        return getDatapointName(a).localeCompare(getDatapointName(b))
      })
    },
  },
  actions: {
    async getDatapointsBySite({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/datapoints/site/' + data).then(res => {
          res.data.data.map(d => d.custom_name = getDatapointName(d))
          commit('SET_DATAPOINTS', res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getTypeDatapoints() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/type_datapoints').then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateDatapoint/' + data.datapoint_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createDatapoint', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteDatapoint/' + data.datapoint_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
