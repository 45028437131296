import axios from "@/plugins/axios";

export default {
  namespaced: true,

  actions: {
    // eslint-disable-next-line
    getTypeDatapoints() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getTypeDatapoints').then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getOneTypeDatapointDetails(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getTypeDatapoint/' + id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createTypeDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createTypeDatapoint', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    deleteTypeDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteTypeDatapoint/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateTypeDatapoint(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateTypeDatapoint/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getTypeDatapointsIcons() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getTypeDatapoints/icons').then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
