import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    // eslint-disable-next-line
    async getSchedulerCalendarsData({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/bms/schedulers/calendars/' + data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateScheduler(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/schedulers', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createScheduler(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/schedulers', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteScheduler(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/schedulers', { data: data }).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
