import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    gatewaySWVersion: [],
    gateways: [],
    unboundGateways: []
  },
  mutations: {
    SET_GATEWAYS(state, value) {
      state.gateways = value
    },
    SET_GATEWAY_SOFTWARE_VERSION(state, value) {
      state.gatewaySWVersion = value
    },
    SET_UNBOUND_GATEWAYS(state, value) {
      state.unboundGateways = value
    }
  },
  getters: {
    getGateways(state) {
      return state.gateways
    },
    getGatewaySoftwareVersion(state) {
      return state.gatewaySWVersion
    },
    getUnboundGateways(state) {
      return state.unboundGateways
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllGatewaysBySiteId({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getGateways/' + data.id + '/' + data.type).then(res => {
          const sorted = res.data.data.data.sort((a, b) => a.site?.customer_id?.name === b.site?.customer_id?.name ? a.site?.name.localeCompare(b.site?.name) : a.site?.customer_id?.name?.localeCompare(b.site?.customer_id?.name))
          commit('SET_GATEWAYS', sorted)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllGateways({ commit }, type = 'edge') {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getGateways/' + type).then(res => {
          res.data.data.data.forEach(g => {
            if (g.keepalive) {
              g.communication = Date.now() - Date.parse(g.keepalive) < (30 * 60 * 1000 * 4);
            } else {
              g.communication = null
            }
          })
          const sorted = res.data.data.data.sort((a, b) => a.site?.customer_id?.name === b.site?.customer_id?.name ? a.site?.name.localeCompare(b.site?.name) : a.site?.customer_id?.name?.localeCompare(b.site?.customer_id?.name))
          commit('SET_GATEWAYS', sorted)
          resolve(sorted)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getGatewaySWVersion({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getGatewaySWVersion').then(res => {
          commit('SET_GATEWAY_SOFTWARE_VERSION', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getUnboundGateways({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getUnboundGateways').then(res => {
          commit('SET_UNBOUND_GATEWAYS', res.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateGateway(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateGateway/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateGatewayConfig(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/updateGatewayConfig/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async sendDataSourceAutoDiscovery(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/gatewayDataSourceAutoDiscovery/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async sendDeviceAutoDiscovery(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/gatewayDeviceAutoDiscovery/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async restartGatewaySoftware(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/restartGatewaySoftware/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async rebootGateway(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/rebootGateway/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async shutdownGateway(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/shutdownGateway/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async forceSendMqttConf(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/forceSendMqttConf', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateGatewaySWVersion(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/updateGatewaySWVersion/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
