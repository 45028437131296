import axios from "@/plugins/axios";

export default {
  namespaced: true,

  actions: {
    async getUsers() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/users').then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateUser/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createUser', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteUser/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async inviteUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/inviteUser', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
