import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    bacnet_devices: []
  },
  mutations: {
    SET_BACNET_DEVICES(state, value) {
      state.bacnet_devices = value
    }
  },
  getters: {
    getBacnetDevices(state) {
      return state.bacnet_devices
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllBacnetDevicesByGatewayId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getBacnetDevices/' + id).then(res => {
          commit('SET_BACNET_DEVICES', res.data.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getCodecTypes() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/codec/types').then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllBacnetDevices({ commit },) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getBacnetDevices').then(res => {
          commit('SET_BACNET_DEVICES', res.data.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createBacnetDevice(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createBacnetDevice', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteBacnetDevice(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteBacnetDevice/' + id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateBacnetDevice(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateBacnetDevice/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
