<template>
<!--  <div id="app-sidebar-10" class="layout-sidebar">-->
<!--    <div class="flex h-full">-->
<!--      <div style="height:60px" class="flex flex-column h-full flex-shrink-0 select-none" @mouseenter="hover=true" @mouseleave="hover=false">-->
<!--        <template v-for="(item,i) of itemMenu" :key="item.label || i">-->
<!--          <li :class="[{'active-menuitem': menuSelected === item.name}]" role="none">-->
<!--            <router-link v-if="item.to" :to="item.to" :style="item.style" :class="[item.class, 'p-ripple']" exact-->
<!--                         exact-active-class="active-route" @click="selectMenu(item)" role="menuitem"-->
<!--                         v-tooltip.right="{value:item.label, class: 'layout-tooltip'}" v-ripple>-->
<!--              <i :class="['layout-menuitem-icon', item.icon]"></i>-->
<!--              <span class="layout-menuitem-text">{{item.label}}</span>-->
<!--            </router-link>-->
<!--          </li>-->
<!--        </template>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="layout-sidebar flex flex-column" style="background-color: #1B2842" :style="{width: currentSidebarWidth + 'px'}">
    <div class="overflow-y-auto">
      <ul class="list-none p-2 m-0" v-for="(item,i) of itemMenu" :key="item.label || i">
        <li>
          <div v-if="item.children">
            <div v-ripple class="p-3 flex align-items-center text-indigo-300 cursor-pointer p-ripple"
                 v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
              <i v-if="item.icon && item.icon.startsWith('pi')" :class="item.icon" class="mr-2 text-xl"></i>
              <font-awesome-icon v-if="item.icon && item.icon.startsWith('fa')" :icon="item.icon" class="mr-2 text-xl"></font-awesome-icon>
              <span class="font-medium text-lg">{{ item.label }}</span>
              <i class="pi pi-chevron-down ml-auto"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden" :class="{'hidden': item.hidden && !menuInManagement}">
              <li v-for="(child, j) of item.children" :key="child.label || j">
                <div v-if="child.children">
                  <div v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple"
                     v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                    <i v-if="child.icon && child.icon.startsWith('pi')" :class="child.icon" class="mr-2 text-xl"></i>
                    <font-awesome-icon v-if="child.icon && child.icon.startsWith('fa')" :icon="child.icon" class="mr-2 text-xl"></font-awesome-icon>
                    <span class="font-medium text-lg">{{ child.label }}</span>
                    <i class="pi pi-chevron-down ml-auto"></i>
                  </div>
                  <ul :class="isMenuInChildren(child.children) || child.unfold ? '' : 'hidden'" class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li v-for="(subchild, k) of child.children" :key="subchild.label || k">
                      <div v-if="subchild.children">
                        <div v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple"
                             v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                          <i v-if="subchild.icon && subchild.icon.startsWith('pi')" :class="subchild.icon" class="mr-2 text-xl"></i>
                          <font-awesome-icon v-if="subchild.icon && subchild.icon.startsWith('fa')" :icon="subchild.icon" class="mr-2 text-xl"></font-awesome-icon>
                          <span class="font-medium text-lg">{{ subchild.label }}</span>
                          <i class="pi pi-chevron-down ml-auto"></i>
                        </div>
                        <ul :class="isMenuInChildren(child.children) || subchild.unfold ? '' : 'hidden'" class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          <li v-for="(subsubchild, l) of subchild.children" :key="subsubchild.label || l">
                            <div v-if="subsubchild.children">
                              <div v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple"
                                   v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                <i v-if="subsubchild.icon && subsubchild.icon.startsWith('pi')" :class="subsubchild.icon" class="mr-2 text-xl"></i>
                                <font-awesome-icon v-if="subsubchild.icon && subsubchild.icon.startsWith('fa')" :icon="subsubchild.icon" style="font-size: 1.23em !important;" class="mr-1"></font-awesome-icon>
                                <span class="font-medium text-lg">{{ subsubchild.label }}</span>
                                <i class="pi pi-chevron-down ml-auto"></i>
                              </div>
                              <ul :class="isMenuInChildren(child.children) || subsubchild.unfold ? '' : 'hidden'" class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                <li v-for="(subsubsubchild, m) of subsubchild.children" :key="subsubsubchild.label || m">
                                  <router-link :class="localMenuSelected === subsubsubchild.name ? 'active-route' : ''" @click="selectMenu(subsubsubchild)" :to="subsubsubchild.to?? ''" v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple">
                                    <i v-if="subsubsubchild.icon && subsubsubchild.icon.startsWith('pi')" :class="subsubsubchild.icon" class="mr-2 text-xl"></i>
                                    <font-awesome-icon v-if="subsubsubchild.icon && subsubsubchild.icon.startsWith('fa')" :icon="subsubsubchild.icon" class="mr-1" style="font-size: 1.23em !important;"></font-awesome-icon>
                                    <span class="font-medium text-lg">{{ subsubsubchild.label }}</span>
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                            <router-link :class="localMenuSelected === subsubchild.name ? 'active-route' : ''" v-else @click="selectMenu(subsubchild)" :to="subsubchild.to?? ''" v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple">
                              <i v-if="subsubchild.icon && subsubchild.icon.startsWith('pi')" :class="subsubchild.icon" class="mr-2 text-xl"></i>
                              <font-awesome-icon v-if="subsubchild.icon && subsubchild.icon.startsWith('fa')" :icon="subsubchild.icon" class="mr-1" style="font-size: 1.23em !important;"></font-awesome-icon>
                              <span class="font-medium text-lg">{{ subsubchild.label }}</span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <router-link :class="localMenuSelected === subchild.name ? 'active-route' : ''" @click="selectMenu(subchild)" :to="subchild.to?? ''" v-else v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple">
                        <i v-if="subchild.icon && subchild.icon.startsWith('pi')" :class="subchild.icon" class="mr-2 text-xl"></i>
                        <font-awesome-icon v-if="subchild.icon && subchild.icon.startsWith('fa')" :icon="subchild.icon" class="mr-2 text-xl"></font-awesome-icon>
                        <span class="font-medium text-lg">{{ subchild.label }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <router-link :class="localMenuSelected === child.name ? 'active-route' : ''" @click="selectMenu(child)" :to="child.to?? ''" v-else v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple">
                  <i v-if="child.icon && child.icon.startsWith('pi')" :class="child.icon" class="mr-2 text-xl"></i>
                  <font-awesome-icon v-if="child.icon && child.icon.startsWith('fa')" :icon="child.icon" class="mr-2 text-xl"></font-awesome-icon>
                  <span class="font-medium text-lg">{{ child.label }}</span>
                </router-link>
              </li>
            </ul>
          </div>
          <router-link :class="localMenuSelected === item.name ? 'active-route' : ''" @click="selectMenu(item)" :to="item.to?? ''" v-else v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round transition-colors transition-duration-150 p-ripple">
            <i v-if="item.icon && item.icon.startsWith('pi')" :class="item.icon" class="mr-2 text-xl"></i>
            <font-awesome-icon v-if="item.icon && item.icon.startsWith('fa')" :icon="item.icon" class="mr-2 text-xl"></font-awesome-icon>
            <span class="font-medium text-lg">{{ item.label }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="mt-auto">
      <hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600" />
      <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
        <li>
          <a @click="logout()" v-ripple class="flex align-items-center cursor-pointer p-3 text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 border-round
                                    transition-duration-150 transition-colors p-ripple">
            <i class="pi pi-sign-out mr-2"></i>
            <span class="font-medium text-lg">Déconnexion</span>
          </a>
        </li>
      </ul>
      <a v-ripple class="m-3 px-3 py-2 flex align-items-center border-round cursor-pointer text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600
                                transition-duration-150 transition-colors p-ripple"
         v-styleclass="{ selector: '@prev', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
        <span class="font-medium text-lg">{{ me?.first_name + ' ' + me?.last_name }}</span>
        <i class="pi pi-chevron-up ml-auto"></i>
      </a>
    </div>
  </div>
  <Dialog v-model:visible="loading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner />
  </Dialog>
</template>

<script>
import NavigationItems from '@/config/navigation-items'
import {mapState} from 'vuex'

export default {
  props: ['currentSidebarWidth'],
  computed: {
    ...mapState({
      menuSelected: state => state.sidebar.selected,
      me: state => state.auth.me,
    }),
    itemMenu () {
      return NavigationItems
    },
  },
  data () {
    return {
      loading: false,
      currentMenu: null,
      hover: false,
      localMenuSelected: '',
      flatMenuManagement: null,
      menuInManagement: false,
      initRoute: false
    }
  },
  watch: {
    '$route.name' (val) {
      document.title = this.$route.meta.title
      this.localMenuSelected = val
      if (!this.initRoute) {
        this.flatMenuManagement = this.flattenJSON(this.itemMenu[1])
        this.menuInManagement = Object.values(this.flatMenuManagement).includes(val)
        this.initRoute = true
      }
    },
  },
  methods: {
    isMenuInChildren (children) {
      if (children.find(c => c.name === this.localMenuSelected)) {
        return true
      }
      for (const child of children) {
        if (child.children) {
          const resp = this.isMenuInChildren(child.children)
          if (resp) return resp
        }
      }
      return false
    },
    flattenJSON (obj = {}, res = {}, extraKey = '') {
      for(const key in obj){
        if(typeof obj[key] !== 'object'){
          res[extraKey + key] = obj[key]
        }else{
          this.flattenJSON(obj[key], res, `${extraKey}${key}.`)
        }
      }
      return res
    },
    async logout () {
      this.loading = true
      await new Promise(resolve => setTimeout(resolve, 500))
      await this.$store.dispatch('auth/logout')
      this.loading = false
      this.$router.push({ name: 'Login'})
    },
    selectMenu (item) {
      if (!item.name) {
        return
      }
      if (item.to) {
        this.$store.dispatch('sidebar/changeMenu', item.name)
        this.$router.push({ name: item.to })
      }
    }
  },
}
</script>

<style scoped>
.svg-inline--fa {
  font-size: 1.6em !important;
 }
.triangle-ripple {
  /*clip-path: polygon(0% 0%, 100% 50%, 0% 100%);*/
  clip-path: circle(40%);
}
</style>
