import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    graph_presets: [],
  },
  mutations: {
    SET_GRAPH_PRESETS(state, value) {
      state.graph_presets = value
    },
  },
  getters: {
    getGraphPresets(state) {
      return state.graph_presets.sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  actions: {
    // eslint-disable-next-line
    async getAllGraphPresets({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getGraphPresets').then(res => {
          commit('SET_GRAPH_PRESETS', res.data.data)
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createGraphPreset(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createGraphPreset', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateGraphPreset(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateGraphPreset/' + data.id, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteGraphPreset(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteGraphPreset/' + id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
