import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    customers: [],
  },
  mutations: {
    SET_CUSTOMERS(state, value) {
      state.customers = value
    },
  },
  getters: {
    getCustomers(state) {
      return state.customers
    },
  },
  actions: {
    async getCustomers({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/customers').then(res => {
          let customers = res.data.data
          commit('SET_CUSTOMERS', customers)
          resolve(customers)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateCustomer(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateCustomer/' + data.customer_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createCustomer(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createCustomer', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteCustomer(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteCustomer/' + data.customer_id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
