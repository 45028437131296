import axios from "@/plugins/axios";

export default {
  namespaced: true,

  actions: {
    async getUserGroups() {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/userGroups').then(res => {
          resolve(res.data.data.sort((a, b) => a.name.localeCompare(b.name)))
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateUserGroup(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateUserGroup/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createUserGroup(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createUserGroup', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteUserGroup(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteUserGroup/' + data.id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
