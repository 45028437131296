export default {
  namespaced: true,

  state: {
    socket: null
  },
  mutations: {
    SET_SOCKET (state, val) {
      state.socket = val
    }
  },
  getters: {
  },
  actions: {
    // async connectMQTT ({ commit }, data) {
    //
    // },
  }
}
