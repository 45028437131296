import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    equipments: [],
    types: [],
    statuses: [],
    icons: []
  },
  mutations: {
    SET_EQUIPMENTS(state, value) {
      state.equipments = value
    },
    SET_TYPES(state, value) {
      state.types = value
    },
    SET_STATUSES(state, value) {
      state.statuses = value
    },
    SET_ICONS(state, value) {
      state.icons = value
    },
  },
  getters: {
    getEquipments(state) {
      return state.equipments.sort((a, b) => {
        if (a.zone_id?.name !== b.zone_id?.name) {
          return a.zone_id?.name?.localeCompare(b.zone_id?.name)
        }
        return a.name?.localeCompare(b.name)
      })
    },
    getTypes(state) {
      return state.types
    },
    getStatuses(state) {
      return state.statuses
    },
    getIcons(state) {
      return state.icons
    },
  },
  actions: {
    async getEquipmentsBySite({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/all/' + data).then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          commit('SET_EQUIPMENTS', sortedArray)
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getEquipmentsBySiteForManagement({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/all/' + data + '/management').then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          commit('SET_EQUIPMENTS', sortedArray)
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getEquipmentCompound(_, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/compound/' + id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getMeterEquipmentsBySite({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/meter/' + data).then(res => {
          const sortedArray = res.data.data.sort((a, b) => a.name.localeCompare(b.name))
          commit('SET_EQUIPMENTS', sortedArray)
          resolve(sortedArray)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateEquipment/' + data.equipment_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async setEquipmentValue(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/equipments/' + data.unique_data_id + '/' + data.value, {fromBms: true}).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateCompoundEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateCompoundEquipment/' + data.equipment_id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createEquipment', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/deleteEquipment/' + data.equipment_id).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getEquipmentTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/types').then(res => {
          const types = res.data.sort((a, b) => a.text.localeCompare(b.text))
          commit('SET_TYPES', types)
          resolve(types)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getEquipmentStatuses({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/statuses').then(res => {
          commit('SET_STATUSES', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getEquipmentIcons({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments/icons').then(res => {
          commit('SET_ICONS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
